import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    sidebarMenu: false,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarMenu = !state.sidebarMenu;
    },
  },
});

export const { toggleSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
