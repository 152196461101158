import {
  LINK_GITHUB,
  LINK_LIVEDEMO_NETFLIXGPT,
  LINK_LIVEDEMO_YOURDENTALCARE,
  LINK_LIVEWEBSITE_INDILEARN,
} from "./constants";
import coverIndilearn from "../assets/coverIndilearn.png";
import coverYourDental from "../assets/coverYourDental.png";
import coverNetflixClone from "../assets/coverNetflixClone.png";


const dataProjects = [
  {
    info: {
      id: "1",
      img: coverIndilearn,
      title: "Indilearn's Website",
      desc: "Indilearn is an information technology company that specializes in developing academic information systems for campuses. I designed and developed their website, which features a responsive interface and functionalities to effectively present their products, services, and company information.",
      techStack: "Wordpress | Elementor",
      liveDemo: LINK_LIVEWEBSITE_INDILEARN,
    },
  },
  // {
  //   info: {
  //     id: "2",
  //     img: coverYourDental,
  //     title: "Your Dental Care Landing Page",
  //     desc: "Designed and developed a landing page website that contains the company profile information for Your Dental Care Makassar",
  //     techStack: "Tailwind CSS",
  //     liveDemo: LINK_LIVEDEMO_YOURDENTALCARE,
  //     github: LINK_GITHUB,
  //   },
  // },
  {
    info: {
      id: "2",
      img: coverNetflixClone,
      title: "Netflix Clone + GPT Search App",
      desc: "Developing a Netflix clone app equipped with GPT-based search functionality, providing a streaming experience similar to Netflix. This project combines an intuitive user interface with GPT technology to deliver more accurate content recommendations and search results.",
      techStack: "Tailwind CSS | React-Redux | Firebase Auth",
      liveDemo: LINK_LIVEDEMO_NETFLIXGPT,
      github: LINK_GITHUB,
    },
  },
];

export default dataProjects;
