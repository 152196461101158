import { configureStore } from "@reduxjs/toolkit"; 
import modeReducer from "./darkModeSlice";
import menuReducer from "./sidebarSlice";

const appStore = configureStore({
    reducer:  {
        mode: modeReducer, 
        sidebar: menuReducer,
       
    }
}); 

export default appStore;