import React from "react";
import { useSelector } from "react-redux";
import { LINK_DRIBBBLE, LINK_GITHUB, LINK_LINKEDIN } from "../utils/constants";


const Footer = () => {
  const isDarkMode = useSelector((store) => store.mode.darkMode);

  return (
    <>
      <div>
        <div
          className={`border rounded-full mt-4 mb-4 ${
            isDarkMode ? "border-gray-900" : "border-gray-100"
          }`}
        ></div>
        <div className="pb-10 pt-8 items-center">
          <ul className="flex justify-center gap-10 font-bold text-gray-400 cursor-pointer mb-3">
            <div className="flex justify-center items-center gap-2">
              <a href={LINK_GITHUB}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-github"
              >
                <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
              </svg>
              </a>
            </div>
            <div className="flex justify-center items-center gap-2">
            <a href={LINK_LINKEDIN}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-linkedin"
              >
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
              </a>
            </div>
            <div className="flex justify-center items-center ">
            <a href={LINK_DRIBBBLE}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-dribbble"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M8.56 2.75c4.37 6.03 6.02 9.42 8.03 17.72m2.54-15.38c-3.72 4.35-8.94 5.66-16.88 5.85m19.5 1.9c-3.5-.93-6.63-.82-8.94 0-2.58.92-5.01 2.86-7.44 6.32"></path>
              </svg>
              </a>
            </div>
          </ul>
          <p className="text-gray-400 text-base text-center pt-1 xs:text-sm sm:text-sm md:text-base lg:text-base">
            © 2024 Mutmainnah, All Rights Reserved{" "}
          </p>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Footer;
