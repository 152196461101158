import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeMode } from "../utils/darkModeSlice";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";

const DarkMode = () => {
  const dispatch = useDispatch();

  const isDarkMode = useSelector((store) => store.mode.darkMode);

  const handleDarkMode = () => {
    dispatch(changeMode(isDarkMode));
  };

  return (
    <>
      <div
        className={`cursor-pointer pt-2 pb-1 px-3 items-center rounded-lg ${
          isDarkMode
            ? "bg-gray-800 hover:opacity-55"
            : "bg-gray-100 hover:opacity-55"
        } `}
      >
        <button onClick={handleDarkMode}>
          {isDarkMode ? (
            <SunIcon className="w-7 h-7 text-yellow-200 stroke-2 " />
          ) : (
            <MoonIcon className="w-7 h-7  text-blue-950 stroke-2" />
          )}
        </button>
      </div>
    </>
  );
};

export default DarkMode;
