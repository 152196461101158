import React from "react";
import dataProjects from "../utils/dataProjects";
import { useSelector } from "react-redux";

const Projects = ({ img, title, desc, techStack, liveDemo, github }) => {
  const isDarkMode = useSelector((store) => store.mode.darkMode);
  return (
    <>
      <div className="grid grid-cols-2 mb-10 mt-32 gap-5 xs:block xs:mx-5 sm:block sm:mx-5 md:grid md:grid-cols-3 md:gap-5 lg:grid lg:grid-cols-3 lg:gap-5">
        {dataProjects.map((project) => (
          <div>
            <div
              key={project.id}
              className={`h-full mb-4 xs:mb-4 sm:mb-4 md:mb-4 lg:mb-4 ${
                isDarkMode
                  ? "bg-gray-900 border border-gray-700 rounded-lg shadow"
                  : "bg-white border border-gray-200 rounded-lg shadow"
              }`}
            >
              <a href="#">
                <img className="rounded-t-lg" src={project.info.img} alt="" />
              </a>
              <div className="p-5">
                <a href={project.info.liveDemo}>
                  <h5
                    className={`mb-2 text-2xl font-bold tracking-tight ${
                      isDarkMode
                        ? "text-gray-300"
                        : "text-gray-900 dark:text-white"
                    }`}
                  >
                    {project.info.title}
                  </h5>
                </a>
                <p
                  className={`mb-3 font-normal ${
                    isDarkMode
                      ? "text-gray-300"
                      : "text-gray-700"
                  }`}
                >
                  {project.info.desc}
                </p>
                <div className="my-3">
                  <p
                    className={`font-semibold text-lg ${
                      isDarkMode
                        ? "text-gray-300"
                        : "text-gray-900"
                    }`}
                  >
                    Tech Stack :
                  </p>
                  <p
                    className={`text-base font-normal ${
                      isDarkMode
                        ? "text-gray-300"
                        : "text-gray-700"
                    }`}
                  >
                    {project.info.techStack}
                  </p>
                </div>

                <a
                  href={project.info.liveDemo}
                  className={`inline-flex justify-center xs:text-sm sm:text-sm md:text-base lg:text-base w-full items-center gap-2 py-3 rounded-lg text-center  text-sm font-semibold border-2 ${
                    isDarkMode
                      ? "border-gray-700  text-gray-200  hover:bg-pink-600 border hover:border-pink-600  hover:text-white"
                      : "border-pink-600  text-pink-800  hover:bg-pink-600 hover:text-white"
                  }`}
                >
                  Live Project
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-external-link"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Projects;
