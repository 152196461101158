import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import { Provider, useDispatch, useSelector} from "react-redux";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Error from "./components/Error";
import Projects from "./components/Projects";
import appStore from "./utils/appStore";



// AppLayout Component
const AppLayout = () => {



  const isDarkMode = useSelector((store) => store.mode.darkMode);
  const isOpenSidebar = useSelector((store) => store.sidebar.sidebarMenu);




 
  return (
    <>
     
     <div className={`flex flex-col overflow-hidden min-h-screen ${isDarkMode ? 'bg-gray-950 text-white' : 'bg-gray-50 text-black'}`}> 
      <Header />
          <main className={`${isOpenSidebar ? "xs:blur-sm sm:blur-sm md:blur-none lg:blur-none" : "blur-none"}`}>
          <Outlet />
          </main>
          <Footer />
    </div> 

    


    </>
    
  );
};

// Router Setup
const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <About />,
      },
      {
        path: "/projects",
        element: <Projects />,
      },
    ],
    errorElement: <Error />,
  },
]);

// Render App
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={appStore}>
    <RouterProvider router={appRouter} />
  </Provider>
);
